import React from "react"
import { graphql } from "gatsby"
import { css } from "@emotion/react"

import Layout from "../components/layout"
import GeneralFooter from "../components/general_footer"
import { TitleHeader, wrapper } from "../components/defaults/styles/elements"
import SubNav from "../components/main/sub_nav"
import { color } from "../components/defaults/styles/theme"
import RearviewIcon from "../components/defaults/assets/svg/rearview.svg"
import {
  RvHandler,
  RvCardList,
  RvCardListItem,
} from "../components/rearview_components"

const Rearview = ({ data, pageContext }) => {
  const { tags, filtered } = pageContext

  console.log(tags)

  const seo = {
    frontmatter: {
      title: "Rearview",
    },
  }

  return (
    <Layout seo={seo}>
      <TitleHeader
        title="Rearview"
        icon={{
          svg: <RearviewIcon />,
          style: "fill",
        }}
        subtitle="EXPO2024 has come and gone, but don’t you worry, the content is forever. In fact, we are even adding new features and updated materials to our website on a regular basis, so keep this page bookmarked and be sure to come visit us again soon."
      />
      <div css={wrapper}>
        <SubNav
          nav_data={tags}
          rearview
          legende="<p>Choose the category of clips you would like to see our scroll through the list of all available videos.</p>"
        />
      </div>
      <section
        css={css`
          background: ${color.main_light};
          padding-top: 2em;
          padding-bottom: 5em;
          margin-top: -2.5rem;
        `}
      >
        <div css={[wrapper, css``]}>
          <RvCardList>
            <RvHandler
              data={filtered ? data.filtered.edges : data.allAirtable.edges}
            />
            <RvCardListItem placeholder />
            <RvCardListItem placeholder />
            <RvCardListItem placeholder />
          </RvCardList>
        </div>
      </section>
      <GeneralFooter theme={color.main_light} />
    </Layout>
  )
}

export default Rearview

export const query = graphql`
  query rearview($tag: String = "Null", $filtered: Boolean = false) {
    allAirtable(
      filter: { data: { publish: { eq: true } }, table: { eq: "rearview" } }
      sort: { fields: [data___sort, data___rearview_title], order: ASC }
    ) @skip(if: $filtered) {
      edges {
        node {
          recordId
          data {
            publish
            sort
            rearview_tags
            password_protected
            rearview_thumbnail {
              localFiles {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
                }
              }
            }
            rearview_description {
              childMarkdownRemark {
                excerpt(format: PLAIN, pruneLength: 240)
              }
            }
            rearview_release
            rearview_title
            rearview_partners {
              data {
                name
              }
            }
            rearview_startups {
              data {
                startup
              }
            }
            rearview_agenda {
              data {
                agenda_start_time
              }
            }
          }
        }
      }
    }
    filtered: allAirtable(
      filter: {
        data: { publish: { eq: true }, rearview_tags: { eq: $tag } }
        table: { eq: "rearview" }
      }
      sort: { fields: [data___sort, data___rearview_title], order: ASC }
    ) @include(if: $filtered) {
      edges {
        node {
          recordId
          data {
            publish
            sort
            rearview_tags
            password_protected
            rearview_thumbnail {
              localFiles {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
                }
              }
            }
            rearview_description {
              childMarkdownRemark {
                excerpt(format: PLAIN, pruneLength: 240)
              }
            }
            rearview_release
            rearview_title
            rearview_partners {
              data {
                name
              }
            }
            rearview_startups {
              data {
                startup
              }
            }
            rearview_agenda {
              data {
                agenda_start_time
              }
            }
          }
        }
      }
    }
  }
`
